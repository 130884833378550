/* eslint-disable react/button-has-type */
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type Sizes = 'sm' | 'md' | 'lg' | 'xl';
export type Variants = 'solid' | 'outline' | 'underline';

export type ButtonProps = {
  className?: string,
  type: 'submit' | 'reset' | 'button',
  size?: Sizes,
  icon?: ReactNode,
  variant?: Variants,
  text: string | ReactNode,
  error?: boolean,
} & React.ComponentPropsWithoutRef<'button'>;

const Button = ({
  type = 'button',
  disabled,
  size = 'md',
  className,
  icon,
  text,
  error,
  variant = 'solid',
  ...props
}: ButtonProps) => {
  const defaultClasses = 'w-fit h-fit whitespace-nowrap rounded-full text-blue-100';

  const solidVariant = {
    sizes: {
      sm: 'text-sm py-2 px-4 [&>div>svg]:w-4 [&>div>svg]:h-4',
      md: 'py-3 px-4 [&>div>svg]:w-4 [&>div>svg]:h-4',
      lg: 'py-4 px-6 font-museo-700 [&>div>svg]:w-6 [&>div>svg]:h-6',
      xl: 'py-5 px-8 font-museo-700 [&>div>svg]:w-6 [&>div>svg]:h-6',
    },
    fontDefaults: 'flex justify-center gap-2 items-center',
    base: 'bg-green-100',
    hover: 'hover:bg-green-radiant',
    focus: 'focus:outline-none focus:ring-[3px] focus:ring-green-30 focus:ring-offset-0',
    active: 'active:ring-0 active:bg-green-60',
    disabled: 'disabled:bg-cursor-default disabled:bg-green-20 disabled:hover:bg-none disabled:text-opacity-40 [&>div>svg]:disabled:fill-grey-400',
  };

  const outlineVariant = {
    sizes: {
      sm: 'text-sm py-2 px-4 [&>div>svg]:w-4 [&>div>svg]:h-4',
      md: 'py-3 px-4 [&>div>svg]:w-4 [&>div>svg]:h-4',
      lg: 'py-4 px-6 font-museo-700 [&>div>svg]:w-6 [&>div>svg]:h-6',
      xl: 'py-5 px-8 font-museo-700 [&>div>svg]:w-6 [&>div>svg]:h-6',
    },
    fontDefaults: 'flex justify-center gap-2 items-center',
    base: 'border-2 border-green-100',
    hover: 'hover:border-green-60 hover:outline-none',
    focus: 'focus:outline-none focus:ring-[3px] focus:ring-green-30 focus:ring-offset-0',
    active: 'active:border-green-200 active:outline-none active:ring-0',
    disabled: 'disabled:border-2 disabled:cursor-default disabled:border-green-20 disabled:text-grey-500 [&>div>svg]:disabled:fill-grey-400',
  };

  const underlineVariant = {
    sizes: {
      sm: 'text-2xs',
      md: 'text-2xs',
      lg: 'text-sm before:h-[2px]',
      xl: 'font-museo-700 before:h-[2px] [&>div>svg]:w-6 [&>div>svg]:h-6',
    },
    fontDefaults: 'flex justify-center gap-2 items-center [&>div>svg]:w-4 [&>div>svg]:h-4 relative before:absolute before:bottom-[-1px] before:h-[1px] before:w-full before:mx-auto before:bg-green-60 hover:before:bg-teal-60',
    base: 'px-2',
    hover: '',
    focus: 'focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-green-30 focus-visible:outline-offset-[1px] focus-visible:rounded-[4px]',
    active: 'active:before:bg-teal-60',
    disabled: 'disabled:before:bg-green-20 [&>div>svg]:disabled:fill-grey-400 disabled:text-grey-400',
  };

  const variantsClasses = {
    solid: solidVariant,
    outline: outlineVariant,
    underline: underlineVariant,

  };

  const selectedVariant = variantsClasses[variant];

  const buttonStyles = twMerge(
    defaultClasses,
    selectedVariant.base,
    selectedVariant.hover,
    selectedVariant.focus,
    selectedVariant.active,
    selectedVariant.disabled,
    className,
  );

  const spanStyles = twMerge(
    selectedVariant.fontDefaults,
    selectedVariant.sizes[size],
  );

  return (
    <button
      type={type}
      className={buttonStyles}
      disabled={disabled}
      {...props}
    >
      <span className={spanStyles}>
        {text}
        {icon}
      </span>
    </button>
  );
};

export default Button;
