'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';
import Button from 'orbit/src/components/Buttons/Button';
import IconButton from 'orbit/src/components/Buttons/IconButton';
import {
  Email, Padlock, Eye, ShutEye,
} from 'orbit/src/components/Icons';
import Input from 'orbit/src/components/Inputs/Input';
import { Statuses } from 'common/lib/types/user';
import UnderlinedLink from '@/src/components/Typography/UnderlinedLink';
import useLoginForm from '@/src/hooks/useLoginForm';

export default function Login() {
  const [error, setError] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const {
    handleChange, errors, canSubmit, values,
  } = useLoginForm();

  const onLogin = async () => {
    setIsLoading(true);
    const { email, password } = values;

    try {
      setError('');
      const auth = await signIn('credentials', {
        email,
        password,
        redirect: false,
        callbackUrl: '/',
      });

      if (!auth?.ok || auth?.error) {
        if (auth?.error === Statuses.Deactivated) {
          setError('Account has been deactivated');
        } else {
          setError('Wrong username or password');
        }
      }

      if (!auth?.error) {
        router.push('/');
      }
    } catch (err) {
      console.error('login error', err);
    }
    setIsLoading(false);
  };

  return (
    <>
      {!!error && (
      <div className="bg-rose-100 py-2 w-full lg:pl-6">
        <span className="text-white text-xs sm:text-sm block text-center lg:text-left">{error}</span>
      </div>
      )}

      <div className="mt-10 pb-10 flex flex-col items-center w-full max-w-[482px]">

        <h1 className="font-museo-700 text-3xl text-blue-100 mb-4">WELCOME BACK!</h1>
        <h2 className="text-xl text-blue-100 mb-10">Login to Groundsure.io</h2>

        <form
          id="login-form"
          className="w-full px-10 flex flex-col justify-center gap-6"
          onSubmit={(e) => {
            e.preventDefault();
            onLogin();
          }}
        >

          <Input
            id="email"
            name="email"
            type="email"
            value={values.email}
            placeholder="Email"
            data-testid="email"
            required
            onChange={handleChange}
            useErrorLabel
            error={errors.email}
            startIcon={<Email className="ml-4 fill-grey-500" />}
          />

          <Input
            type={showPassword ? 'text' : 'password'}
            name="password"
            id="password"
            data-testid="password"
            required
            placeholder="Password"
            value={values.password}
            onChange={handleChange}
            useErrorLabel
            error={errors.password}
            startIcon={<Padlock className="ml-4 fill-grey-500" />}
            endIcons={[
              <IconButton
                type="button"
                variant="embedded"
                icon={showPassword ? <Eye /> : <ShutEye />}
                onClick={() => (setShowPassword((prevState) => !prevState))}
              />]}
          />

          <Button
            className="mb-4 w-full"
            data-testid="login-button"
            type="submit"
            onClick={onLogin}
            disabled={isLoading || !canSubmit}
            text={isLoading ? 'logging in...' : 'login'}
          />

          <div className="w-full">
            <UnderlinedLink href="/forgot-password">Forgot password?</UnderlinedLink>
          </div>
        </form>
      </div>
    </>
  );
}
