import useForm from 'orbit/src/hooks/useForm';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  email: Yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup
    .string()
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

const useLoginForm = () => {
  const {
    handleChange, errors, isValid, values,
  } = useForm(initialValues, validationSchema);

  return {
    canSubmit: isValid,
    handleChange,
    errors,
    values,
  };
};

export default useLoginForm;
